<template>
  <div class="text-grid-row" :style="'width:' + width + 'px;'">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.text-grid-row {
  display: flex;
  justify-content: space-between;
}
</style>
